import React, { useEffect, useRef } from 'react';
import './Numpad.scss'

const Numpad = ({ handleInputChange, handleDelete, handleOKClick }) => {

  const timeoutRef = useRef(null);
  const shouldStopDelete = useRef(false);

  useEffect(() => {
    //This use effect is to stop the start deleting function 
    // if somehow it fails to stop automatically
    if (shouldStopDelete.current) {
      shouldStopDelete.current = false;
      stopDeleting();
    }

    return () => {
      stopDeleting();
    }
  }, [shouldStopDelete.current]);

  const handleValueChange = (value) => {
    handleInputChange(value);
  };

  const startDeleting = () => {
    timeoutRef.current = setTimeout(function deleteCharacter() {
      handleDelete(); // Continue to delete characters
      timeoutRef.current = setTimeout(deleteCharacter, 100); // Call again after 100ms
      setTimeout(() => { shouldStopDelete.current = true }, 2000);
    }, 200);
  };

  const stopDeleting = () => {
    // Clear the timeout when the button is released
    clearTimeout(timeoutRef.current);
  };

  return (
    <div className="numpad-container">
      <div className="container-fluid">
        <div className="header-btn">
          <button className='btnClass' onClick={() => handleOKClick()}>{'บันทึก'}</button>
        </div>
      </div>
      <div className="numpad">
        <div className="numpad-row">
          {[1, 2, 3].map((number) => (
            <button key={number} onClick={() => handleValueChange(number.toString())}>{number}</button>
          ))}
        </div>
        <div className="numpad-row">
          {[4, 5, 6].map((number) => (
            <button key={number} onClick={() => handleValueChange(number.toString())}>{number}</button>
          ))}
        </div>
        <div className="numpad-row">
          {[7, 8, 9].map((number) => (
            <button key={number} onClick={() => handleValueChange(number.toString())}>{number}</button>
          ))}
        </div>
        <div className="numpad-row">
          <button onClick={() => handleValueChange('.')}>.</button>
          <button onClick={() => handleValueChange('0')}>0</button>
          <button
            onMouseDown={startDeleting}  // For starting deletion on press
            onMouseUp={stopDeleting}     // For stopping deletion on release
            onMouseLeave={stopDeleting}  // Stop deletion if mouse leaves the button area
            onTouchStart={startDeleting} // For mobile touch events
            onTouchEnd={stopDeleting}    // For stopping on touch end (mobile)
            className="numpad-row-delete-btn">
            <img onContextMenu={event => event.preventDefault()} src="/images/healthAndWellness/numpad-union.svg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Numpad;